// File generated by the postcss-es-modules plugin. Please do not modify it !!!
import { injectStyles } from 'css-es-modules';
const options = {"useConstructableStylesheet":false};
const key = '33374955f9bb38734d697a94688ec299';
const css =`._defaultMenu_mw2h9_1{cursor:pointer;padding:0}._defaultMenuList_mw2h9_1:focus{outline:2px solid transparent;outline-offset:2px}._defaultMenuItem_mw2h9_1{--tw-border-opacity:1;align-items:center;border-color:rgb(221 221 221/var(--tw-border-opacity));border-top-width:1px;display:flex;flex-direction:row;margin:0;padding:.5rem .75rem;text-align:left;width:100%}._defaultMenuItem_mw2h9_1:first-child{border-top-width:0}._defaultMenuItem_mw2h9_1:hover{--tw-bg-opacity:1;background-color:rgb(238 238 238/var(--tw-bg-opacity))}._defaultMenuItem_mw2h9_1 svg{--tw-text-opacity:1;color:rgb(105 105 105/var(--tw-text-opacity));height:1.25rem;margin-right:.5rem;transform:translateY(.06rem);width:1.25rem}._defaultMenuItemActive_mw2h9_1{--tw-bg-opacity:1;background-color:rgb(238 238 238/var(--tw-bg-opacity))}`;
const styles = {
    get ['defaultMenu']() { injectStyles(key, css, options);  return '_defaultMenu_mw2h9_1'; },
    get ['defaultMenuList']() { injectStyles(key, css, options);  return '_defaultMenuList_mw2h9_1'; },
    get ['defaultMenuItem']() { injectStyles(key, css, options);  return '_defaultMenuItem_mw2h9_1'; },
    get ['defaultMenuItemActive']() { injectStyles(key, css, options);  return '_defaultMenuItemActive_mw2h9_1'; },
    inject() { injectStyles(key, css, options); }
};
export { styles, css, key };
export default styles;
